
@use "@angular/material" as mat;
@import "~swiper/swiper-bundle.min.css";
@include mat.core();
$ClientPortal-primary: mat.define-palette(mat.$indigo-palette);
$ClientPortal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$ClientPortal-warn: mat.define-palette(mat.$red-palette);
$ClientPortal-theme: mat.define-light-theme(
  (
    color: (
      primary: $ClientPortal-primary,
      accent: $ClientPortal-accent,
      warn: $ClientPortal-warn,
    ),
  )
);
@include mat.all-component-themes($ClientPortal-theme);
@import "./colors.scss";

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: transparent;
}
.mdc-dialog .mdc-dialog__content {
  padding: 0px !important;
}
.mat-mdc-dialog-surface {
  border-radius: 22px !important;
  /* Adjust the value as needed */
}
li{
  cursor: pointer;
}
@keyframes opac {
  0% { opacity: .2; }
  50% { opacity: .5; }
  100% { opacity: 1; }
}

.rotating-element {
  animation: opac 1.5s linear infinite;
}
@keyframes bounceUpDown {
  0% { transform: translateY(0); }   
  50% { transform: translateY(20px); } 
  100% { transform: translateY(0); } 
}

.animated-element {
  animation: bounceUpDown 1s ease-in-out infinite; /* Runs forever */
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  animation: rotate 2s linear infinite;
}
.cursor-pointer{
  cursor: pointer;
}